<template>
 
     
     <div class="AboutSection">
            <div class="AboutSection-one">高端恋爱管家团队</div>
            <div class="AboutSection-Single">High end Love Butler Team</div>
            <div class="AboutSection-two">全程管家式服务，深度陪同，多重认证，严格审核真实信息</div>
            <div class="AboutSection-img"> <img @click="gotoBackx" src="../assets/gengd.png" alt=""></div>

           <div  style="margin-top: 30px;">
               <div class="AboutSection-p" >
                <Vue3SeamlessScroll  :list="formlist"  v-model="iftrue"   :step="1"   direction="left" >
                    <div class="AboutSection-xsl" >
                        <div class="AboutSection-chuld"  :style="item.show?'width: 400px;  height: 578px;  margin-top: 20px;transform: scale(1);transition: all 1s ease-out;  opacity: 1!important;  box-shadow: 1px 10px 10px 0px #BEBBBB;':'transition: all .5s ease-out; width: 360px; height: 520px;'" v-for="item in formlist" :key="item.teacherId"  @mouseenter.stop="handleMouseEnter(item)" @mouseleave.stop="handleMouseLeave(item)">
                          <img class="AboutSection-l" :src="item.image" alt="">
                          <img class="AboutSection-ico" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/57078d33-382a-4ad0-b593-cc755eb06261.png" alt="">
                          <img class="AboutSection-icoxs" src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/8f841ecc-cb2b-4fc0-b8da-82129d73ff34.png" alt="">
                          <div    :style="item.show?'height: 285px;transform: scale(1);  transition: all 1s ease-out;':'transition: all .5s ease-out;'"  :class=" item.show?'AboutSection-liesx':'AboutSection-lie'">
                        <div class="AboutSection-name">{{item.name}}</div>
                        <div class="AboutSection-lo">
                            <div class="AboutSection-lole">
                               <div class="AboutSection-lolepan">{{ item.serviceYears }}</div>
                               <div class="AboutSection-lolepans">工作经验</div>
                            </div>
                            <div class="AboutSection-lole">
                               <div class="AboutSection-lolepan">{{ item.serviceNums }}</div>
                               <div class="AboutSection-lolepans">服务会员</div>
                            </div>
                            <div class="AboutSection-lole">
                               <div class="AboutSection-lolepan">{{ item.serviceHours }}</div>
                               <div class="AboutSection-lolepans">咨询小时</div>
                            </div>

                        </div>
                        <div :class=" item.show? 'AboutSection-btnol': 'AboutSection-btn'" @click="gotoBack(item.id)">
                            咨询
                        </div>
                        </div>
                        </div>
                  
                  
                    </div>
           
                </Vue3SeamlessScroll>
               
             </div>
          
           </div>
           

           
     </div>
     

  
     
</template>

<script >


import {getTeacherList }from '../axios/dev'
import router from "../router/router"
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
   data(){
       return{
    
        iscorm:true,
        testShowxr:false,
        iftrue:true,
        classOptions:{ 
            direction: 3,
            limitMoveNum: 2
        },
        optionCustomer: {
        step: 1,
        limitMoveNum: 5,
        openTouch: false,
        waitTime: 1,
        direction: 2,
        singleWidth: 30
      },
formlist:[],
       }
   },
   components:{

   Vue3SeamlessScroll,
   
   
   
    // cssSeamlessScroll
   },
   

   computed: {
    optionHover () {
      return {
        openWatch: true,
         step: 0.2, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
           hoverStop: true, // 是否开启鼠标悬停stop
          direction: 3, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    },
},





   mounted(){
 

    
      getTeacherList({
        size:20,
        platformType:3,
        current:1,
      }).then(res=>{
         this.formlist=res.data.list
   
          
       })
   },
   methods:{
    gotoBackx(){
        router.push({
           path: '/Matchmaking',
         
            })

    },
        gotoBack(item){
router.push({
           path: '/moonDetails',
           query: {
             id: item
           }
            })
   },
    handleMouseEnter(item) {
           item.show=true
           this.iftrue=false
   },
   handleMouseLeave(item) {
       item.show=false
       this.iftrue=true

   },
  


    
   },
  

}
</script >

<style scoped >
.AboutSection{
   width: 100%;
   height: 866px;
  margin-top: -20px;
  /* display:flex; */
  overflow: hidden;
  background: linear-gradient( 180deg, #F5F3FF 0%, rgba(204,190,255,0) 50%);
   /* background-color: #F1EEFF; */
}
.scroll-wrap {
  height:200px;
  width: 800px;
  overflow: hidden;
}
.AboutSection-one{
   margin: 20px auto; 
   font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 56px;
color: #051850;
line-height: 82px;
text-align: left;
margin-left: 11%;
font-style: normal;

}
.AboutSection-Single{
 
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
color: rgba(0,0,0,0.1);
margin-top: -35px;
letter-spacing: 6px;
text-align: left;
margin-left: 11%;
font-style: normal;
text-transform: uppercase;
}
.AboutSection-two{

   /* margin: 0px auto;  */
   font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 24px;
margin-top: 10px;
/* border: 1px solid #ccc; */
color: #999;
line-height: 33px;
text-align: left;
margin-left: 11%;
font-style: normal;


}
.AboutSection-img{
   width: 106px;
   height: 113px;
   float: right;
   margin-right: 149px;
   margin-top: -80px;
}
.AboutSection-p{
   width: 100%;
   height: 740px;
   display: flex;
   margin-left: 10%;
   overflow: hidden;
   border-radius: 10px;
    /* white-space: nowrap; */
   
}
.AboutSection-xsl{
    width: 100%;
    height: 686px;
   background-color: #fff;
   border-radius: 10px;
    overflow: hidden;
    display: flex;
}


.AboutSection-chuld{
  width: 360px;

  margin-top: 60px;
  margin-left: 30px;
 
  background-color: #F8F8F8;
  /* border: 1px solid #051850; */
  border-radius: 10px;
  position: relative;

}

.AboutSection-chuld:last-child{
    width: 360px;

margin-top: 60px;
margin-left: 30px;

background-color: #F8F8F8;
/* border: 1px solid #051850; */
border-radius: 10px;
position: relative;
    margin-right: 30px;
}
.AboutSection-l{
    width: 100%;
    height: 300px;
    border-radius: 10px 10px 0 0 ;
}
.AboutSection-ico{
    width: 57px;
    height: 26px;
    position: absolute;
    right: 116px;
    top: 264px;
}
.AboutSection-icoxs{
    width: 97px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 264px;
}
.AboutSection-lie{
    width: 100%;
    height: 220px;
   
    /* background-color: red; */
}
.AboutSection-liesx{
    width: 100%;
    height: 220px;
    background:url('https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/d452d630-5188-4110-8d92-e82372c1e080.png') no-repeat center 0px ;
	background-position: center 0px;
    background-size: cover;
    /* background-color: red; */
}
.AboutSection-name{
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 24px;
color: #222222;
line-height: 33px;
padding-top: 20px;

font-style: normal;
}
.AboutSection-lo{
    width: 100%;
    height: 60px;
  
    margin-top: 50px;
    display: flex;

}
.AboutSection-lole{
    flex: 1;
    width: 33%;
    

}
.AboutSection-lolepan{
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-family: DINAlternate, DINAlternate;
font-weight: bold;
font-size: 24px;
color: #222222;
    
}

.AboutSection-lolepans{
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #666666;
  
}
.AboutSection-btn{
    width: 230px;
height: 48px;
border-radius: 10px;
border: 1px solid #24272C;
text-align: center;
line-height: 48px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #2C2E32;
margin: 15px auto;
}
.AboutSection-btnol{
    width: 256px;
height: 54px;
background: #783DF9;
border-radius: 10px;
text-align: center;
line-height: 48px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #fff;
margin: 35px auto;
}
.warp{
    width: 100%;
    height: 600px;
}

</style>