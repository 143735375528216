<template>
	<!-- Footer start -->
	<div id="aders">
		<div class="aders-xer">

			<div class="aders-xechuld" @click="goBack(1)">关于月娘</div>
			<div class="aders-xechuld" @click="goBack(2)">用户协议</div>
			<div class="aders-xechuld" @click="goBack(3)">隐私协议</div>
			<div class="aders-xechuld" @click="goBack(4)">返回首页</div>
		</div>
		<div class="aders-xff">
			<span @click="onClkierr(1)" style="color: #5076CB;"
				>京公网安备11010502050624号</span>

			<!-- <span @click="onClicks"> </span> -->
			<span @click="onClkierr(2)"  style="margin-left: 30px; color: #5076CB;">京ICP备2020048669号-2</span>
			<img src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/2849abe8-e9ff-4b6f-8fe1-2a2d78b95df6.png"
				alt="">
			<!-- <span> </span> -->
		</div>
		<div class="aders-xffs">
			不良和违法信息举报专线: <span style="color: #5076CB;">010-58700085 (9:30-20:00)</span> 商务洽谈合作请联系：商务总监 <span
				style="color: #5076CB;">13910942725</span>
		</div>
		<img class="aders-xmer"
			src="https://online-miniapp-1304146125.cos.ap-beijing.myqcloud.com/f4ce1063-49ed-41e4-aa06-fc0c8aa41927.png"
			alt="">

	</div>
	<!-- Footer end -->
</template>

<script>
import router from "../router/router"
export default {
	methods: {
		// onClicks(){

		// },
		onClkierr(i){
			if(i==1){
				window.open("https://beian.mps.gov.cn/#/query/webSearch?recordcode=11010502050624")

			}else{
				window.open("https://beian.miit.gov.cn/#")
				
			}

		},
		goBack(item) {
			if (item == 1) {

				localStorage.setItem('ifNum', 7);
				router.push('/concerningYn')
			} else if (item == 2) {
				window.open("/#/ServiceAgreement")
				// 用户协议
			} else if (item == 3) {
				window.open("/#/PrivacyAgreement")
				// 隐私协议
			} else {
				router.push('/')

				// 返回首页
			}
		}
	}
}
</script>

<style>
#aders {
	width: 100%;
	height: 220px;
	background: #1E1C2E;
	position: relative;
}

.aders-xer {
	width: 544px;
	height: 28px;
	display: flex;
	position: absolute;
	top: 42px;
	left: 35%;

}

.aders-xechuld {
	flex: 1;

	text-align: center;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 20px;
	color: #FFFFFF;
	line-height: 28px;
	/* text-align: right; */
	font-style: normal;
}

.aders-xff {
	width: 100%;
	cursor: pointer;
	height: 30px;
	text-align: center;
	line-height: 30px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 12px;
	color: #7F7F7F;
	line-height: 26px;
	letter-spacing: 1px;
	/* text-align: right; */
	font-style: normal;
	position: absolute;
	top: 100px;
	left: 0;
	position: relative;

}

.aders-xff img {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 7px;
	left: 40.3%;

}

.aders-xffs {

	width: 100%;
	height: 30px;
	text-align: center;
	line-height: 30px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 12px;
	color: #7F7F7F;
	line-height: 26px;
	letter-spacing: 1px;
	/* text-align: right; */
	font-style: normal;
	position: absolute;
	top: 130px;
	left: 0;
}

.aders-xff img {
	width: 12px;
	height: 12px;
}

.aders-xmer {
	width: 76px;
	height: 145px;
	position: absolute;
	right: 80px;
	top: 40px;

}
</style>