<template>
	<div id="app">
		<div v-if="$route.meta.keepAlive">
				<NavbarElement ></NavbarElement>
				<router-view v-if="isShow">
		</router-view>
	
			
	
		</div>
	<div v-else>
		<router-view v-if="isShow">
		</router-view>
	</div>
		
		
	</div>
	


	   
	   
	<!-- Navbar start -->

	<!-- Footer end -->
</template>

<script>
import { provide } from "vue";
// import DevicePixelRatio from './utils/devicePixelRatio';
import NavbarElement from '@/components/NavbarElement.vue';
import DevicePixelRatio from './components/devicePixelRatio';

export default {
	provide(){
return {reload: this.reload};
},
data(){
	return{
		isShow:true
	}
},
	created(){
    
		console.log(navigator.userAgent.toLowerCase(),'11221212121212');
		new DevicePixelRatio().init();
	},
	methods:{
		reload(){
			this.isShow= false;
			// console.log('王科举');
			this.$nextTick(()=>{this.isShow = true
		})
	},

		},
		components:{
		NavbarElement
	}

	}
</script>

<style>
 html,body,#app{
    height: 100%;
  }
  video{
    width: 100%;
        &::-webkit-media-controls {
        overflow: hidden !important;
    }
    &::-webkit-media-controls-enclosure {
        width: calc(100% + 15px);
        margin-left: auto;
    }
}



</style>
